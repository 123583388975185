import React from 'react';
import CurriculumSelector from '../../components/CurriculumSelector';
import DigitalLessonBundle from '../../components/DigitalLessonBundle';
import { Column, Container, Row } from '../../components/Grid';
import Image from '../../components/Image';
import Layout from '../../components/Layout';
import Resource from '../../components/Resource';
import Section from '../../components/Section';
import Video from '../../components/Video';
import Action from '../../components/Resource/Action';

const TypesOfInteractionsPage = ({ data }) => (
  <Layout title="Types of Interactions">
    <Section>
      <Container>
        <Row>
          <Column size={11}>
            <h1 className="page-header">Types of Interactions</h1>
          </Column>
        </Row>
        <Row>
          <Column size={8}>
            <Video
              guidSrc="3f6c3271-2077-4eec-ab86-099b850483ef"
              title="Types of Interactions"
            />
          </Column>
          <Column className="pt-3 pl-3 pr-3" size={4}>
            <p>
              Inspire students to interact with their environment, investigate
              important challenges, and create original solutions by tapping
              into the universal laws of nature. Watch the topic series video to
              learn how students can harness the Laws of Motion to ignite
              innovation and pursue exciting STEM careers emerging in the
              Industry 4.0 revolution.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    {/************** VTS GUIDE **************/}
    <Section>
      <Container>
        <Row className="educator-guide-forces">
          <Column size={4}>
            <Image filename="resources-image-EdGuide.png" />
          </Column>
          <Column size={6} className="educator-guide-column">
            <h3>Video Topic Series Guide</h3>
            <p>
              The Caterpillar Foundation Video Series Guide for high school
              physics is a standards-aligned curriculum of engaging videos and
              hands-on activities designed to bring the world of STEM and
              Industry 4.0 into the classroom. Students learn the fundamentals
              of physical laws and how to problem solve by tackling real-world
              challenges in class and after-school sessions. Through a process
              of investigation, team discussion, and hands-on experiences,
              students will advance theory into applications they can see and
              touch.
            </p>
          </Column>
          <Column size={2} className="bottom-button">
            <div className="resource__actions resource__actions--aligned">
              <Action
                label="Download"
                dropdown={true}
                dropdownItemClassname="vts__dropdown-items"
                actions={[
                  {
                    label: 'English',
                    download: '/pdfs/Caterpillar-VTS-EdGuide.pdf',
                    metadata: '.PDF, 410KB',
                  },
                  {
                    label: 'Spanish',
                    download: '/pdfs/Caterpillar-VTS-EdGuide_ES.pdf',
                    metadata: '.PDF, 418KB',
                  },
                  {
                    label: 'French',
                    download: '/pdfs/Caterpillar-VTS-EdGuide_FR.pdf',
                    metadata: '.PDF, 436KB',
                  },
                ]}
              />
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/************** VTS ACTIVITIES **************/}
    <Section>
      <Container>
        <Row>
          <Column size={12}>
            <h2>Hands-On Classroom Activities</h2>
          </Column>
        </Row>
        <Row>
          <Column size={9}>
            <p>
              Discover how the Laws of Motion play a part in every single thing
              we do with these simple, easy-to-implement activations aligned to
              education standards. Students will take on a series of creative
              STEM challenges in which they put the power of force and motion
              into action.
            </p>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <Resource
              img="interactions-image-activity1.png"
              subjects={['Classroom Activity']}
              title={'Heavy Lifting'}
              audience="How are magnetism and gravity similar?"
              description="Unlock the power of magnetic attraction as students build a simple crane out of household materials and use it to create an original game."
              actions={[
                {
                  label: 'Download',
                  dropdown: true,
                  actions: [
                    {
                      label: 'English',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-HeavyLifting.pdf',
                      metadata: '.PDF, 763KB',
                    },
                    {
                      label: 'Spanish',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-HeavyLifting_ES.pdf',
                      metadata: '.PDF, 932KB',
                    },
                    {
                      label: 'French',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-HeavyLifting_FR.pdf',
                      metadata: '.PDF, 944KB',
                    },
                  ],
                },
              ]}
            />
          </Column>
          <Column size={4}>
            <Resource
              img={'interactions-image-activity2.png'}
              subjects={['Classroom Activity']}
              title={'Invisible Forces'}
              audience="Does knowing how electricity works change the way you use it?"
              description="Explore the creative ways natural forces can be harnessed with an activity in which students design a tabletop game using electromagnetic game pieces."
              actions={[
                {
                  label: 'Download',
                  dropdown: true,
                  actions: [
                    {
                      label: 'English',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-InvisibleForces.pdf',
                      metadata: '.PDF, 451KB',
                    },
                    {
                      label: 'Spanish',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-InvisibleForces_ES.pdf',
                      metadata: '.PDF, 498KB',
                    },
                    {
                      label: 'French',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-InvisibleForces_FR.pdf',
                      metadata: '.PDF, 506KB',
                    },
                  ],
                },
              ]}
            />
          </Column>
          <Column size={4}>
            <Resource
              img={'interactions-image-activity3.png'}
              subjects={['Classroom Activity']}
              title={'Power of Gravity'}
              audience="Can gravity be “hacked”?"
              description="Take a closer look at the everyday effects of gravity on our lives as students are challenged to make their own DIY helium balloon and achieve liftoff."
              actions={[
                {
                  label: 'Download',
                  dropdown: true,
                  actions: [
                    {
                      label: 'English',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-PowerofGravity.pdf',
                      metadata: '.PDF, 473KB',
                    },
                    {
                      label: 'Spanish',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-PowerofGravity_ES.pdf',
                      metadata: '.PDF, 507KB',
                    },
                    {
                      label: 'French',
                      download:
                        '/pdfs/Caterpillar-TeacherActivity-PowerofGravity_FR.pdf',
                      metadata: '.PDF, 523KB',
                    },
                  ],
                },
              ]}
            />
          </Column>
        </Row>
      </Container>
    </Section>
    {/************** QUOTE **************/}
    <Section>
      <Container>
        <Row>
          <Column size="12" className="forces-quote">
            <Image
              filename="resources-graphic-quotemark.svg"
              className="start-slash"
            />
            <p className="forces-quote-text">
              AI, big data, machine learning, and automation will usher in a new
              and novel world full of interesting careers that don’t even exist
              today.
            </p>
            <Image
              filename="resources-graphic-quotemark.svg"
              className="end-slash"
            />
            <p className="forces-quote-author">– Kat, Host</p>
          </Column>
        </Row>
      </Container>
    </Section>
    {/************** AFTERSCHOOL ACTIVITIES **************/}
    <Section>
      <Container>
        <Row className="section-image-header">
          <Column size={6}>
            <h2>After School Activity</h2>
          </Column>
          <Column size={6}>
            <Image filename="resources-graphic-lineart-ASactivity.svg" />
          </Column>
        </Row>
        <Row>
          <Column size={9}>
            <p>
              Explore the ways that the Laws of Motion can be harnessed in
              students’ everyday lives with a series of engaging activities that
              are easy to implement in any environment.
            </p>
          </Column>
        </Row>
        <Row>
          <DigitalLessonBundle
            resource1={
              <Resource
                img={'types-of-interactions-electric-train.jpg'}
                subjects={['Subject Area']}
                title={'Electric Train'}
                className="magnus-effect"
                description="After designing their train tracks and successfully getting their train to traverse it, students will learn the connection of synchronous AC motors with the shift in the electric car market and how that can push into industry 4.0."
                actions={[
                  {
                    label: 'Download',
                    download:
                      '/pdfs/Caterpillar-AfterSchoolActivity-WirelessElectricTrain.pdf',
                  },
                ]}
              />
            }
            resource2={
              <Resource
                img={'global-image-ASFacilitatorguide@2x.png'}
                title={'Facilitator Guide'}
                className="facilitator-guide"
                description="Students learn the fundamentals of physics and how to solve problems by tackling real-world challenges in out of class and after-school sessions. Through a process of investigation, team discussion, and hands-on experiences, students will advance theory into applications they can see and touch."
                actions={[
                  {
                    label: 'Download',
                    dropdown: true,
                    actions: [
                      {
                        label: 'English',
                        download: '/pdfs/Caterpillar-AfterschoolGuide.pdf',
                        metadata: '.PDF, 606KB',
                      },
                      {
                        label: 'Spanish',
                        download: '/pdfs/Caterpillar-AfterschoolGuide-ES.pdf',
                        metadata: '.PDF, 718KB',
                      },
                      {
                        label: 'French',
                        download: '/pdfs/Caterpillar-AfterschoolGuide-FR.pdf',
                        metadata: '.PDF, 736KB',
                      },
                    ],
                  },
                ]}
              />
            }
            className="dlb-row"
          />
        </Row>
      </Container>
    </Section>
    {/************** CURRICULUM SELECTOR **************/}
    <Section>
      <Container>
        <CurriculumSelector />
      </Container>
    </Section>
    {/************** TESTIMONIAL **************/}
    <Section>
      <Container>
        <Row>
          <Column>
            <Image filename="resources-image-testimonial.png" />
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default TypesOfInteractionsPage;
