import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from '../Button';
import Icon from '../Icon';
import classNames from 'classnames';

import './styles.scss';

const Action = ({
  dropdown,
  label,
  metadata,
  module,
  moduleName,
  link,
  download,
  noIcon,
  asLink,
  disabled,
  actions,
  dropdownItemClassname,
}) => {
  const renderModuleLink = () => {
    return (
      <Button
        to={disabled ? null : module}
        target="_blank"
        forceExternal
        data-click-type="Module Launch"
        data-click-name={moduleName}
        className="resource__action-link"
        disabled={disabled}
      >
        {!noIcon && (
          <Icon
            marginRight
            name="externallink"
            className="resource__action-icon"
          />
        )}
        {label}
      </Button>
    );
  };

  const renderLink = () => {
    return asLink ? (
      <Link
        disabled={disabled}
        to={disabled ? null : link}
        className="resource__action-link"
      >
        {!noIcon && (
          <Icon marginRight name="module" className="resource__action-icon" />
        )}
        {label}
      </Link>
    ) : (
      <Button
        disabled={disabled}
        to={disabled ? null : link}
        className="resource__action-link"
        target="_blank"
      >
        {label}
      </Button>
    );
  };

  const renderDownload = () => {
    return (
      <Button
        to={disabled ? null : download}
        target="_blank"
        rel="noopener noreferrer"
        className="resource__action-link"
        link={asLink}
        disabled={disabled}
      >
        {label}
        {!noIcon && (
          <Icon name="download" className="resource__action-icon" marginLeft />
        )}
      </Button>
    );
  };

  const [dropdownOpen, setDropdownOpen] = useState(null);
  const handleDropdownClick = () => setDropdownOpen((state) => !state);

  const renderDropdown = () => {
    return (
      <>
        <Button
          onClick={handleDropdownClick}
          className={classNames(
            'resource__action-link',
            'resource__dropdown-toggle',
            dropdownOpen && 'resource__dropdown-toggle--open'
          )}
        >
          {label} &nbsp;{' '}
          <Icon
            name="caratdown"
            className={classNames(
              'resource__dropdown-carat',
              dropdownOpen && 'resource__dropdown-carat--open'
            )}
          />
        </Button>
        {dropdownOpen && (
          <div className={`resource__dropdown-items ${dropdownItemClassname}`}>
            {actions.map((action, i) => (
              <Action {...action} key={i} />
            ))}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="resource__action">
      {link && renderLink()}
      {module && renderModuleLink()}
      {download && renderDownload()}
      {metadata && <span className="resource__metadata">{metadata}</span>}
      {dropdown && renderDropdown()}
    </div>
  );
};

Action.defaultProps = {
  disabled: false,
};

Action.propTypes = {
  label: PropTypes.node.isRequired,
  metadata: PropTypes.string,
  module: PropTypes.string,
  download: PropTypes.string,
  noIcon: PropTypes.bool,
  asLink: PropTypes.bool,
  disabled: PropTypes.bool,
  /** Renders a dropdown */
  dropdown: PropTypes.bool,
};

export default Action;
