import { useLocation } from '@reach/router';
import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Icon from '../Icon';
import './styles.scss';

const CurriculumSelector = () => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          menuLinks {
            label
            children {
              label
              path
            }
          }
        }
      }
    }
  `);
  const [nextTopic, setNextTopic] = useState(null);
  const [previousTopic, setPreviousTopic] = useState(null);
  const [currentTopic, setCurrentTopic] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const curriculumTopics = site.siteMetadata.menuLinks.find(
    (link) => link.label === 'Curriculum Resources'
  ).children;

  // This will run once after the initial render.
  useEffect(() => {
    const currentTopicIndex = curriculumTopics.findIndex(
      (topic) => topic.path.replace(/\/$/, '') === pathname.replace(/\/$/, '')
    );
    const previousTopic =
      currentTopicIndex > 0 ? curriculumTopics[currentTopicIndex - 1] : null;
    const nextTopic =
      currentTopicIndex < curriculumTopics.length - 1
        ? curriculumTopics[currentTopicIndex + 1]
        : null;
    setNextTopic(nextTopic);
    setPreviousTopic(previousTopic);
    setCurrentTopic(curriculumTopics[currentTopicIndex]);
  }, []);

  return (
    <div className="curriculum-selector">
      <div className="curriculum-selector__left-section">
        {previousTopic && (
          <div>
            <a
              className="curriculum-selector__button"
              href={previousTopic.path}
            >
              <Icon name="arrowleft" />
            </a>
            Previous Topic
          </div>
        )}
      </div>
      <div className="curriculum-selector__middle-section">
        <p>Select a topic to continue</p>
        <div className="curriculum-selector__dropdown">
          <button onClick={() => setDropdownOpen(!dropdownOpen)}>
            {currentTopic?.label} <Icon name="caratdown" />{' '}
          </button>
          <div
            className={classNames(
              'curriculum-selector__dropdown-content',
              dropdownOpen && 'curriculum-selector__dropdown-content--open'
            )}
          >
            <ul>
              {curriculumTopics.map((topic, index) => (
                <li className="curriculum-selector__dropdown-item" key={index}>
                  <a href={topic.path}>
                    <div>{topic.label}</div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="curriculum-selector__right-section">
        {nextTopic && (
          <div>
            Next Topic
            <a className="curriculum-selector__button" href={nextTopic.path}>
              <Icon name="arrowright" />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default CurriculumSelector;
